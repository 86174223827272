

























import {Vue, Component, Prop} from 'vue-property-decorator';
import {IAbsenceData, DemandeHorsDelais, EtatAbsence} from '@/entities/absence/absence.types';
import {getAbsenceApprovalColor, getAbsenceApprovalIcon, reviveUTCDate} from '@/utils';

@Component({})
export default class extends Vue {
    @Prop({type: Object, required: true})
    private item!: IAbsenceData;

    private get demandeHorsDelais() {
        return this.item.demandeHorsDelais !== DemandeHorsDelais.None;
    }

    private get approvalColor() {
        return getAbsenceApprovalColor(this.etatAbsence);
    }

    private get approvalIcon() {
        return getAbsenceApprovalIcon(this.etatAbsence);
    }

    private get etatAbsence() {
        return (this.$i18n.t('etatAbsence').valueOf() as string[])[this.item.etat];
    }

    private get absenceSubType() {
        return (this.$i18n.t('absenceSubType').valueOf() as string[])[this.item.subType ?? 0];
    }

    private get etatAbsenceProperties(): string[] {
        // eslint-disable-next-line no-restricted-globals
        return Object.keys(EtatAbsence).filter(key => isNaN(Number(key)));
    }

    public get title() {
        return `${this.item.name ?? ''} - ${this.absenceSubType}`
    }

    private get period(): string {
        const startDate = reviveUTCDate(this.item.dateDebut);
        const endDate = reviveUTCDate(this.item.dateFin);
        if (!startDate || !endDate) {
            return '';
        }
        if (startDate.getMonth() === endDate.getMonth()) {
            return this.$i18n
                .t('conges.periodShort', {
                    start: this.$i18n.d(startDate, 'weekdayStart'),
                    end: this.$i18n.d(endDate, 'weekdayEnd')
                })
                .toString();
        }
        if (startDate.getFullYear() !== endDate.getFullYear()) {
            return this.$i18n
                .t('conges.periodShort', {
                    start: this.$i18n.d(startDate, 'weekdayStartFull'),
                    end: this.$i18n.d(endDate, 'weekdayEnd')
                })
                .toString();
        }
        return this.$i18n
            .t('conges.periodShort', {
                start: this.$i18n.d(startDate, 'weekdayStartMonth'),
                end: this.$i18n.d(endDate, 'weekdayEnd')
            })
            .toString();
    }

    private handleClickDetail() {
        this.$emit('select', this.item.id);
    }
}
