import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/store';
import {CreateAbsenceRequest} from '@/models/viewmodels/absence/CreateAbsenceRequest';
import {UpdateAbsenceRequest} from '@/models/viewmodels/absence/UpdateAbsenceRequest';
import {IAbsenceData, EtatAbsence} from '@/entities/absence/absence.types';
import {Absence} from '@/entities/absence/absence';
import {to} from '@/utils';
import {postNewAbsence, updateAbsence, cancelAbsence, getAbsences} from '@/api/absence';
import {ApplicationModule} from './application';
import {UserModule} from './user';


export interface IAbsenceState {
    absences: Array<IAbsenceData>;
    absenceId: string | null;
}

@Module({dynamic: true, store, name: 'absence', namespaced: true})
class MAbsence extends VuexModule implements IAbsenceState {
    public absences: Array<IAbsenceData> = [];
    public absenceId: string | null = null;

    @Mutation
    private SET_ABSENCES(absences: Array<IAbsenceData>) {
        this.absences = absences;
    }

    @Mutation
    private SET_ABSENCEID(id: string | null) {
        this.absenceId = id;
    }

    @Mutation
    private SET_ABSENCE(absence: IAbsenceData) {
        const absences = cloneDeep(this.absences);
        const pIndex = absences.findIndex(abs => abs.id === absence.id);
        if (pIndex !== -1) {
            absences.splice(pIndex, 1);
        }
        absences.push(absence);
        this.absences = absences;
    }

    @Action
    public async serverChantierDataUpdate(data: Array<IAbsenceData>) {
        const absences = cloneDeep(this.absences);
        data.forEach(absence => {
            const pIndex = absences.findIndex(abs => abs.id === absence.id);
            if (pIndex !== -1) {
                if (absence.version <= absences[pIndex].version) {
                    // ignore this version
                    return;
                }
                // Got new version of this pointage
                absences.splice(pIndex, 1);
            }
            absences.push(new Absence(absence)); // <-- Will auto revive dates
        });
        this.SET_ABSENCES(absences);
    }

    @Action
    public async createAbsence(data: CreateAbsenceRequest) {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }

        ApplicationModule.setLoading(true);
        const [err, createResult] = await to(postNewAbsence(data));
        ApplicationModule.setLoading(false);
        if (err) {
            ApplicationModule.setError({message: "Une erreur inconnue s'est produite..."});
            throw new Error();
        }
        if (createResult) {
            if (createResult.succeeded && createResult.absence?.id) {
                this.SET_ABSENCE(createResult.absence);
            } else if (
                createResult.errors &&
                Array.isArray(createResult.errors) &&
                createResult.errors.length > 0
            ) {
                ApplicationModule.setError({
                    message: createResult.errors[0]
                });
                throw new Error();
            }
        }
    }

    @Action
    public async updateAbsence(data: UpdateAbsenceRequest) {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }

        ApplicationModule.setLoading(true);
        const [err, updateResult] = await to(updateAbsence(data));
        ApplicationModule.setLoading(false);
        if (err) {
            ApplicationModule.setError({message: "Une erreur inconnue s'est produite..."});
            throw new Error();
        }
        if (updateResult) {
            if (updateResult.succeeded && updateResult.absence?.id) {
                this.SET_ABSENCE(updateResult.absence);
            } else if (
                updateResult.errors &&
                Array.isArray(updateResult.errors) &&
                updateResult.errors.length > 0
            ) {
                ApplicationModule.setError({
                    message: updateResult.errors[0]
                });
                throw new Error();
            }
        }
    }

    @Action
    public async refreshListAbsences(showHidden: boolean) {
        ApplicationModule.setLoading(true);
        const [, response] = await to(getAbsences(showHidden));
        ApplicationModule.setLoading(false);
        if (response && Array.isArray(response) && response.length > 0) {
            this.SET_ABSENCES(response.map(abs => new Absence(abs)));
        }
    }

    @Action
    public async cancelCurrentAbsence() {
        if (!this.absenceId) {
            return;
        }

        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }

        ApplicationModule.setLoading(true);
        const [err, response] = await to(cancelAbsence(this.absenceId));
        ApplicationModule.setLoading(false);
        if (err || !response) {
            throw new Error('UnknownError');
        }
        const absences = cloneDeep(this.absences);
        const pIndex = absences.findIndex(abs => abs.id === this.absenceId);
        if (pIndex === -1) {
            return;
        }

        absences[pIndex].etat = EtatAbsence.Annulee;
        this.SET_ABSENCES(absences);
        this.SET_ABSENCEID(null);
    }

    public get selected() {
        if (!this.absenceId) {
            return undefined;
        }

        const pIndex = this.absences.findIndex(abs => abs.id === this.absenceId);

        if (pIndex === -1) {
            return undefined;
        }

        return new Absence(this.absences[pIndex]);
    }
}

export const AbsenceModule = getModule(MAbsence);
