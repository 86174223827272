/* eslint-disable-next-line no-shadow */
export enum EtatAbsence {
    DemandeInit = 0,
    VerificationRH,
    Validee,
    Refusee,
    Annulee
}

/* eslint-disable-next-line no-shadow */
export enum DemandeHorsDelais {
    Min14DaysRule,
    Min30DaysRule,
    Min60DaysRule,
    None
}

/* eslint-disable-next-line no-shadow */
export enum AbsenceSubType {
    CongesPayes,
    CongesSansSolde,
    AbsenceAutorisee,
    AbsenceNonAutorisee,
    Maladie,
    AccidentTravail,
    ChomagePartiel,
    Formation,
    Maternite,
    Paternite,
    Naissance,
    /* eslint-disable-next-line camelcase */
    Mariage_PACS,
    MariageEnfant,
    DecesProche,
    Demenagement,
    ConvocationOfficielle,
    AbsenceSanctionDisciplinaire,
    Autre,
    Revisions,
    RecuperationChauffeur,
    CET
}

export interface IAbsenceData {
    id: string;
    commentaireUtilisateur: string | undefined;
    dateDemande: Date | string;
    dateDebut: Date | string;
    dateFin: Date | string;
    demandeHorsDelais: DemandeHorsDelais;
    description: string | undefined;
    etat: EtatAbsence;
    isHidden: boolean;
    motifAnnulation: string | undefined;
    motifRefus: string | undefined;
    name: string;
    subType: AbsenceSubType;
    version: number;
}

export interface IAbsence extends IAbsenceData {
    dateDemande: Date;
    dateDebut: Date;
    dateFin: Date;
}


