























































































































































































































































































import {ApplicationModule} from '@/store/modules/application';
import {Vue, Component, PropSync} from 'vue-property-decorator';
import {
    AbsenceSubType,
    DemandeHorsDelais,
    EtatAbsence,
    IAbsenceData,
} from '@/entities/absence/absence.types';
import {getAbsenceApprovalColor, getAbsenceApprovalIcon, getWorkingDaysAmount, padNumber} from '@/utils';
import {AbsenceModule} from '@/store/modules/absence';
import {
    UpdateAbsenceRequest,
    UpdateAbsenceRequestMode,
} from '@/models/viewmodels/absence/UpdateAbsenceRequest';

import VueSignature from '@/components/User/vue-signature.vue';

@Component({
    components: {
        VueSignature,
    },
})
export default class AbsenceCard extends Vue {
    @PropSync('showDialog') private syncedShowDialog!: boolean;
    public editMode = 0;
    public newName = '';
    public fieldNameError = '';
    public newDescription = '';
    public fieldDescriptionError = '';
    public otherError = '';
    public newCancelComment = '';
    public fieldCancelError = '';
    public newComment = '';
    public toggleDisabled = false;

    private fetes: {[year: number]: {[fete: string]: Date}} = {};

    public get selected() {
        return AbsenceModule.selected;
    }

    public get name() {
        return this.selected?.name ?? '';
    }

    public get commentaireUtilisateur() {
        return this.selected?.commentaireUtilisateur ?? '';
    }

    public get createdDate() {
        return this.selected?.dateDemande || new Date();
    }

    public get dateDebut() {
        return this.selected?.dateDebut || new Date();
    }

    public get dateFin() {
        return this.selected?.dateFin || new Date();
    }

    public get totalDays() {
        return getWorkingDaysAmount(this.dateDebut, this.dateFin, this.fetes);
    }

    public get raisonAbsence() {
        return AbsenceModule.selected?.description;
    }

    public get state() {
        return (this.$i18n.t('etatAbsence').valueOf() as string[])[this.selected?.etat ?? 0];
    }

    public get subType() {
        return (this.$i18n.t('absenceSubType').valueOf() as string[])[this.selected?.subType ?? 0];
    }

    public get computedIsRefused() {
        return this.selected?.etat === EtatAbsence.Refusee;
    }

    public get motifRefus() {
        return this.selected?.motifRefus ?? 'Aucun motif.';
    }

    public get computedIsCancelled() {
        return this.selected?.etat === EtatAbsence.Annulee;
    }

    public get motifAnnulation() {
        return this.selected?.motifAnnulation ?? 'Aucun motif.';
    }

    public get computedIsSubTypeOther() {
        return this.selected?.subType === AbsenceSubType.Autre;
    }

    public get loading() {
        return ApplicationModule.loading;
    }

    public get computedApprovalColor() {
        return getAbsenceApprovalColor(this.state);
    }

    public get computedApprovalIcon() {
        return getAbsenceApprovalIcon(this.state);
    }

    public get computedToggleHiddenIcon() {
        return this.isHidden ? 'mdi-eye' : 'mdi-eye-off';
    }

    public get isHidden() {
        return this.selected?.isHidden;
    }

    private get horsDelais() {
        return (this.$i18n.t('demandeHorsDelais').valueOf() as string[])[
            AbsenceModule.selected?.demandeHorsDelais ?? DemandeHorsDelais.None
        ];
    }

    public get computedIsEditDisabled() {
        const today = new Date();
        return (
            today >= this.dateDebut ||
            this.selected?.etat === EtatAbsence.Refusee ||
            this.selected?.etat === EtatAbsence.Annulee
        );
    }

    public get computedModalTitle() {
        return this.editMode ? 'Modifier la demande' : 'Détails de la demande';
    }

    public handleEditButton() {
        this.newName = this.name;
        this.newComment = this.selected?.commentaireUtilisateur ?? '';
        this.newDescription = this.raisonAbsence ?? '';
        this.editMode = 1;
    }

    public handleCancelButton() {
        this.editMode = 2;
    }

    public handleToggleHiddenButton(): void {
        this.toggleDisabled = true;
        const absenceUpdate = {
            mode: UpdateAbsenceRequestMode.Toggle,
            absence:  {
                ...this.selected,
                isHidden: !this.isHidden,
            } as IAbsenceData
        } as UpdateAbsenceRequest;

        // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
        const _self = this;

        AbsenceModule.updateAbsence(absenceUpdate)
            .then(() => {
                this.newName = '';
                this.newDescription = '';
                this.newComment = '';
                this.syncedShowDialog = false;
                this.newCancelComment = '';
                this.editMode = 0;
                this.toggleDisabled = false;
            })
            .catch(() => {
                this.toggleDisabled = false;
                const errorMessage =
                    ((ApplicationModule.error as any)?.message as string | undefined) ?? 'UNKNOWN';
                switch (errorMessage) {
                    default:
                        _self.otherError = errorMessage;
                        break;
                }
            });
    }

    public postUpdateAbsence() {
        this.fieldNameError = '';
        this.fieldDescriptionError = '';
        this.otherError = '';
        this.fieldCancelError = '';
        let hasErr = false;

        const signPad = (this.$refs.signPad as unknown) as VueSignature;
        if (signPad.isEmpty) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: `Une demande d'absence ne peut-être ${(this.editMode === 1 ? 'modifiée' : 'annulée')} sans être signée.`,
                timeout: 5 * 1000,
            });
            return;
        }

        const absenceUpdate = {} as UpdateAbsenceRequest;

        const nowDateTime = new Date();

        const dformat = `${[
            padNumber(nowDateTime.getDate()),
            padNumber(nowDateTime.getMonth() + 1),
            nowDateTime.getFullYear(),
        ].join('/')} à ${[
            padNumber(nowDateTime.getHours()),
            padNumber(nowDateTime.getMinutes()),
        ].join(':')}`;
        signPad.addWaterMark({
            text: `Signé le ${dformat}`,
            font: '15px Arial', // mark font, > default '20px sans-serif'
            style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
            fillStyle: 'red', // fillcolor, > default '#333'
            strokeStyle: 'blue', // strokecolor, > default '#333'
            x: 50, // fill positionX, > default 20
            y: 200, // fill positionY, > default 20
            sx: 50, // stroke positionX, > default 40
            sy: 200, // stroke positionY, > default 40
        });
        absenceUpdate.signature = signPad.save('image/jpeg');

        if (this.editMode === 1) {
            if (!this.newName) {
                this.fieldNameError =
                    "Veuiller saisir un nom pour que votre demande d'absence soit valide.";
                hasErr = true;
            }
            absenceUpdate.mode = UpdateAbsenceRequestMode.Edit;
            absenceUpdate.absence = {
                ...this.selected,
                name: this.newName,
                description: this.newDescription,
            } as IAbsenceData;
        } else if (this.editMode === 2) {
            if (!this.newCancelComment) {
                this.fieldCancelError = "Veuiller saisir le motif de l'annulation.";
                hasErr = true;
            }
            absenceUpdate.mode = UpdateAbsenceRequestMode.Cancel;
            absenceUpdate.absence = {
                ...this.selected,
                etat: EtatAbsence.Annulee,
                motifAnnulation: this.newCancelComment,
            } as IAbsenceData;
        }

        if (hasErr) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
        const _self = this;

        AbsenceModule.updateAbsence(absenceUpdate)
            .then(() => {
                this.newName = '';
                this.newDescription = '';
                this.newComment = '';
                this.syncedShowDialog = false;
                this.newCancelComment = '';
                this.editMode = 0;
            })
            .catch(() => {
                const errorMessage =
                    ((ApplicationModule.error as any)?.message as string | undefined) ?? 'UNKNOWN';
                switch (errorMessage) {
                    case 'Vous devez renseigner un nom valide.':
                        _self.fieldNameError = errorMessage;
                        _self.newName = _self.name;
                        break;
                    case "Vous devez saisir le motif de l'annulation.":
                        _self.fieldCancelError = errorMessage;
                        _self.newCancelComment = '';
                        break;
                    default:
                        _self.otherError = errorMessage;
                        break;
                }
            });
    }
}
