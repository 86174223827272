












































































































































































































import {ApplicationModule} from '@/store/modules/application';
import {Vue, Component} from 'vue-property-decorator';
import VueSignature from '@/components/User/vue-signature.vue';
import {AbsenceSubType} from '@/entities/absence/absence.types';
import {CreateAbsenceRequest} from '@/models/viewmodels/absence/CreateAbsenceRequest';
import {AbsenceModule} from '@/store/modules/absence';
import {PictureUploadResult} from '@/models/viewmodels/PictureUploadResult';
import {formatDate, isWorkingDay, getWorkingDaysAmount, to, padNumber} from '@/utils';
import filters from '@/modules/filters';
import {HTMLInputEvent} from '@/models/viewmodels/html/HTMLInputEvent';
import {postNewPicture} from '@/api/data';

@Component({
    components: {
        VueSignature,
    },
})
export default class NewAcompteModal extends Vue {
    public showDialog = false;
    public showSelectDateDialog = false;
    public workingDays = 0;
    public step = 1;

    public absSubType = AbsenceSubType.CongesPayes;
    public fieldSubTypeError = '';
    public name = '';
    public fieldNameError = '';
    public description = '';
    public fieldDescriptionError = '';
    public otherError = '';
    public dates: string[] = [];
    public date1!: Date;
    public dateDebut!: Date;
    public dateFin!: Date;
    public fieldDateError = '';
    public commentaireUtilisateur = '';
    public pictures: PictureUploadResult[] = [];

    public uploadProgress = 0;
    public uploadingFile = false;

    private fetes: {[year: number]: {[fete: string]: Date}} = {};

    public get absenceSubTypes() {
        const absSubTypesProps = Object.keys(AbsenceSubType).filter((key) =>
            Number.isNaN(Number(key))
        );
        return absSubTypesProps.map((element, index) => {
            return {
                text: (this.$i18n.t('absenceSubType').valueOf() as string[])[index],
                value: index,
            };
        });
    }

    public get computedIsOther() {
        return this.absSubType === AbsenceSubType.Autre;
    }

    public get computedFormattedDateRange() {
        return this.dates.flatMap((date) => formatDate(date)).join(' - ');
    }

    public get loading() {
        return ApplicationModule.loading;
    }

    private mounted() {
        this.$nextTick(() => {
            this.initNewAbsence();
        });
    }

    private initNewAbsence() {
        this.workingDays = 0;
        this.step = 1;

        this.absSubType = AbsenceSubType.CongesPayes;
        this.fieldSubTypeError = '';
        this.name = '';
        this.fieldNameError = '';
        this.dates = [];
        this.fieldDateError = '';
        this.description = '';
        this.fieldDescriptionError = '';
        this.otherError = '';
        this.pictures = [];
    }

    public getImage(index: number) {
        return filters.imagizer(`${this.pictures[index].id}${this.pictures[index].ext}`, false, 64);
    }

    private isDateAllowed(value: string): boolean {
        let firstDate = new Date(value);
        firstDate = new Date(
            Date.UTC(firstDate.getUTCFullYear(), firstDate.getUTCMonth(), firstDate.getUTCDate())
        );
        return isWorkingDay(firstDate, this.fetes);
    }

    private takePicture() {
        (this.$refs.fileInput as HTMLInputElement).click();
    }

    private async onFileChanged(event?: HTMLInputEvent) {
        if (!event || !event.target || !event.target.files) {
            return;
        }
        const file: File = event.target.files[0];
        if (!file) {
            return;
        }
        if (this.uploadingFile) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Un envoi est déjà en cours...',
                timeout: 5 * 1000,
            });
            return;
        }

        this.otherError = '';
        this.uploadProgress = 0;
        this.uploadingFile = true;

        const formData = new FormData();
        formData.append('image', file, file.name);
        const [, response] = await to(
            postNewPicture(formData, (event) => {
                this.uploadProgress = Math.round((100 * event.loaded) / event.total);
            })
        );
        this.uploadingFile = false;
        if (response && Array.isArray(response) && response.length > 0 && response[0].id) {
            this.pictures.push(response[0]);
        } else {
            this.otherError =
                "Le justificatif n'a pas pu être envoyé. Merci de ré-essayer dans une zone avec une bonne connexion à internet.";
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Une erreur est survenue lors de l'envoie de votre justificatif.",
                timeout: 5 * 1000,
            });
        }
    }

    private remove(index: number): void {
        this.pictures.splice(index, 1);
    }

    private applyNewDate() {
        (this.$refs.datePicker as any).save(this.dates);
        this.validateDates();

        this.showSelectDateDialog = false;
        this.saveNewDates();
    }

    private saveNewDates() {
        if (this.dates.length === 1) {
            this.dateDebut = new Date(this.dates[0]);
            this.dateFin = this.dateDebut;
        } else {
            this.dateDebut = new Date(this.dates[0]);
            this.dateFin = new Date(this.dates[1]);
        }
    }

    private cancelDateChoice() {
        this.showSelectDateDialog = false;

        this.workingDays = getWorkingDaysAmount(this.dateDebut, this.dateFin, this.fetes);
    }

    private validateDates() {
        const newDates = [...this.dates];
        newDates.sort((a, b) => {
            const dateA = new Date(a).getTime();
            const dateB = new Date(b).getTime();
            if (dateA < dateB) {
                return -1;
            }
            if (dateA === dateB) {
                return 0;
            }
            return 1;
        });

        let dateA!: Date;
        let dateB!: Date;
        if (this.dates.length === 1) {
            dateA = new Date(this.dates[0]);
            dateB = dateA;
        } else {
            dateA = new Date(this.dates[0]);
            dateB = new Date(this.dates[1]);
        }
        this.workingDays = getWorkingDaysAmount(dateA, dateB, this.fetes);

        this.dates = newDates;
    }

    public nextStep2() {
        this.fieldSubTypeError = '';
        this.fieldDescriptionError = '';
        this.fieldNameError = '';
        this.fieldDateError = '';
        let hasErr = false;
        if (!this.name) {
            this.fieldNameError =
                "Veuiller saisir un nom pour que votre demande d'absence soit valide.";
            hasErr = true;
        }
        if (!Object.values(AbsenceSubType).includes(this.absSubType)) {
            this.fieldSubTypeError = 'Veuiller sélectionner un type pour votre absence.';
            hasErr = true;
        }
        if (this.absSubType === AbsenceSubType.Autre && !this.description) {
            this.fieldDescriptionError = 'Indiquer la raison de votre absence.';
            hasErr = true;
        }
        if (this.workingDays === 0) {
            this.fieldDateError = 'Sélectionner la date de début et de fin souhaitée.';
            hasErr = true;
        }
        if (hasErr) {
            return;
        }
        this.step = 2;
    }

    public postNewAbsence() {
        this.fieldSubTypeError = '';
        this.fieldNameError = '';
        this.fieldDateError = '';
        this.fieldSubTypeError = '';
        this.otherError = '';
        let hasErr = false;
        if (!this.name) {
            this.fieldNameError =
                "Veuiller saisir un nom pour que votre demande d'absence soit valide.";
            hasErr = true;
        }
        if (!Object.values(AbsenceSubType).includes(this.absSubType)) {
            this.fieldSubTypeError = 'Veuiller sélectionner un type pour votre absence.';
            hasErr = true;
        }
        if (this.absSubType === AbsenceSubType.Autre && !this.description) {
            this.fieldDescriptionError = 'Indiquer la raison de votre absence.';
            hasErr = true;
        }
        if (this.workingDays === 0) {
            this.fieldDateError = 'Sélectionner la date de début et de fin souhaitée.';
            hasErr = true;
        }
        if (hasErr) {
            this.step = 1;
            return;
        }

        const signPad = this.$refs.signPad as unknown as VueSignature;
        if (signPad.isEmpty) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Une demande d'absence ne peut-être envoyée sans être signée.",
                timeout: 5 * 1000,
            });
            return;
        }

        const absence = {
            name: this.name,
            comments: this.commentaireUtilisateur,
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            pictures: this.pictures.map((pic) => pic.id),
            raisonAbsence: this.description,
            subType: this.absSubType,
        } as CreateAbsenceRequest;

        const nowDateTime = new Date();

        const dformat = `${[
            padNumber(nowDateTime.getDate()),
            padNumber(nowDateTime.getMonth() + 1),
            nowDateTime.getFullYear(),
        ].join('/')} à ${[
            padNumber(nowDateTime.getHours()),
            padNumber(nowDateTime.getMinutes()),
        ].join(':')}`;
        signPad.addWaterMark({
            text: `Signé le ${dformat}`,
            font: '15px Arial', // mark font, > default '20px sans-serif'
            style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
            fillStyle: 'red', // fillcolor, > default '#333'
            strokeStyle: 'blue', // strokecolor, > default '#333'
            x: 50, // fill positionX, > default 20
            y: 200, // fill positionY, > default 20
            sx: 50, // stroke positionX, > default 40
            sy: 200, // stroke positionY, > default 40
        });
        absence.signature = signPad.save('image/jpeg');

        // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
        const _self = this;

        AbsenceModule.createAbsence(absence)
            .then(() => {
                signPad.clear();
                this.initNewAbsence();
                this.showDialog = false;
                this.$emit('created');
            })
            .catch(() => {
                const errorMessage =
                    ((ApplicationModule.error as any)?.message as string | undefined) ?? 'UNKNOWN';
                signPad.clear();
                switch (errorMessage) {
                    case 'Vous devez renseigner un nom valide.':
                        _self.fieldNameError = errorMessage;
                        _self.step = 1;
                        _self.name = '';
                        break;
                    case 'Veuiller sélectionner un type pour votre absence.':
                        _self.fieldSubTypeError = errorMessage;
                        _self.step = 1;
                        _self.absSubType = AbsenceSubType.CongesPayes;
                        break;
                    case "La date de début n'est pas valide.":
                    case "La date de fin n'est pas valide.":
                    case "Il n'y a pas de jours ouvrés dans la période sélectionnée.":
                        _self.fieldDateError = errorMessage;
                        _self.dates = [this.date1.toISOString().substr(0, 10)];
                        _self.validateDates();
                        _self.saveNewDates();
                        _self.step = 1;
                        break;
                    case 'Vous devez spécifier le mois sur lequel votre acompte sera débité.':
                        _self.fieldSubTypeError = errorMessage;
                        this.absSubType = AbsenceSubType.CongesPayes;
                        _self.step = 1;
                        break;
                    default:
                        _self.otherError = errorMessage;
                        break;
                }
            });
    }
}
