import {reviveUTCDate} from '@/utils';
import {IAbsence, IAbsenceData, EtatAbsence, DemandeHorsDelais, AbsenceSubType} from './absence.types';

export class Absence implements IAbsence {
    public id: string;
    public commentaireUtilisateur: string | undefined;
    public dateDemande: Date;
    public dateDebut: Date;
    public dateFin: Date;
    public demandeHorsDelais: DemandeHorsDelais;
    public description: string | undefined;
    public etat: EtatAbsence;
    public isHidden: boolean;
    public motifAnnulation: string | undefined;
    public motifRefus: string | undefined;
    public name: string;
    public subType: AbsenceSubType;
    public version: number;

    constructor(data: IAbsenceData) {
        this.id = data.id;
        this.commentaireUtilisateur = data.commentaireUtilisateur;
        this.dateDemande = reviveUTCDate(data.dateDemande);
        this.dateDebut = reviveUTCDate(data.dateDebut);
        this.dateFin = reviveUTCDate(data.dateFin);
        this.demandeHorsDelais = data.demandeHorsDelais;
        this.description = data.description;
        this.etat = data.etat;
        this.isHidden = data.isHidden;
        this.motifAnnulation = data.motifAnnulation;
        this.motifRefus = data.motifRefus;
        this.name = data.name;
        this.subType = data.subType;
        this.version = data.version;
    }
}
