








































































import {Vue, Component} from 'vue-property-decorator';
import AbsenceCardComponent from '@/components/Absence/AbsenceCard.vue';
import NewAbsenceComponent from '@/components/Absence/NewAbsence.vue';
import AbsenceListItem from '@/components/Absence/AbsenceListItem.vue';
import AideAbsence from '@/components/Aide/AideAbsence.vue';
import IllustrationLakeMountainComponent from '@/components/Illustrations/LakeMountain.vue';
import {AbsenceModule} from '@/store/modules/absence';
import {IAbsenceData} from '@/entities/absence/absence.types';

@Component({
    components: {
        AbsenceCardComponent,
        NewAbsenceComponent,
        AbsenceListItem,
        AideAbsence,
        IllustrationLakeMountainComponent,
    },
})
export default class AbsencesView extends Vue {
    private dialogAddOpened = false;
    private bShowDetailsDialog = false;
    public displayHidden = false;
    public showSuccess = false;
    public displayHelp = false;

    private created() {
        AbsenceModule.refreshListAbsences(this.displayHidden);
    }

    public get computedNothingToDisplay() {
        return !this.listeDesAbsences || this.listeDesAbsences.length === 0;
    }

    get listeDesAbsences(): Array<IAbsenceData> {
        return AbsenceModule.absences.filter((abs) => abs.isHidden === this.displayHidden);
    }

    public get computedToggleHiddenIcon() {
        return this.displayHidden ? 'mdi-eye-off' : 'mdi-eye';
    }

    public handleDisplayButton() {
        this.displayHidden = !this.displayHidden;
        AbsenceModule.refreshListAbsences(this.displayHidden);
    }

    public handleSelectionChanged(id: string): void {
        this.$store.commit('absence/SET_ABSENCEID', id);
        this.bShowDetailsDialog = true;
    }

    public handleNewAbsenceCreated() {
        this.showSuccess = true;
    }
}
