import { IAbsenceData } from '@/entities/absence/absence.types';

/* eslint-disable-next-line no-shadow */
export enum UpdateAbsenceRequestMode {
    Edit,
    Cancel,
    Toggle
}
export interface UpdateAbsenceRequest {
    mode: UpdateAbsenceRequestMode;
    absence: IAbsenceData;
    signature: string;
}

