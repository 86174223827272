





















































































































































































































































































































import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class IllustrationLakeMountainComponent extends Vue {
    @Prop({type: String}) title!: string;
    @Prop({type: String}) subtitle!: string;
}
