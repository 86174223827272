import request from '@/utils/request';
import {IAbsenceData} from '@/entities/absence/absence.types';
import {CreateAbsenceRequest} from '@/models/viewmodels/absence/CreateAbsenceRequest';
import {CreateAbsenceResult} from '@/models/viewmodels/absence/CreateAbsenceResult';

export const getAbsences = (showHidden: boolean): Promise<Array<IAbsenceData>> =>
    (request({
        url: `/Absence/MesDonnees?ShowHidden=${showHidden}`,
        method: 'get'
    }) as unknown) as Promise<Array<IAbsenceData>>;

export const postNewAbsence = (absence: CreateAbsenceRequest): Promise<CreateAbsenceResult> =>
    (request({
        url: '/Absence/Create',
        method: 'post',
        data: absence
    }) as unknown) as Promise<CreateAbsenceResult>;

export const updateAbsence = (data: any): Promise<any> =>
    (request({
        url: '/Absence/Update',
        method: 'post',
        data
    }) as unknown) as Promise<any>;

export const cancelAbsence = (data: string): Promise<void> =>
    (request({
        url: `/Absence/CancelAbsence/${data}`,
        method: 'post'
    }) as unknown) as Promise<any>;